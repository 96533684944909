<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>班级列表</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建班级</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="班级名">-->
      <!--                            <a-select-option value="username">班级名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">班级名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row style="background-color: #fff;height: 90%;">
        <a-col :push="4" :span="20">

          <div v-show="Class_show === false" :style="{ padding: '24px', background: '#fff', minHeight: '100%' }">
            <a-result title="请选择课程">

            </a-result>
          </div>

          <div v-show="Class_show=== true" :style="{ padding: '24px', background: '#fff', minHeight: '100%' }">
            <a-row justify="space-between" type="flex">
              <a-col :span="6">
                <!--                <h1>分类名称: {{ Class_name }}</h1>-->
              </a-col>
              <a-col :span="18" style="text-align:right;margin-bottom:10px">
                <!--                <a-button style="margin-right: 10px" type="primary" @click="addArticle(Class_id)">-->
                <!--                  新建班级-->
                <!--                </a-button>-->
                <a-button style="margin-right: 10px" type="primary" @click="editClass(Class_id)">
                  编辑课程
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="startClass(Class_id)">
                  发布课程
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="stopClass(Class_id)">
                  暂停课程
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="releaseClass(Class_id)">
                  恢复课程
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="terminationClass(Class_id)">
                  中止课程
                </a-button>


                <a-button style="margin-right: 0px" type="primary" @click="deleteClass(Class_id)">
                  剔除课程
                </a-button>

              </a-col>

            </a-row>
            <!--                                {{data}}-->
            <div>
                            {{class_info}}
              <a-descriptions bordered style="margin-bottom:20px" title="相关信息">
                <a-descriptions-item :span="3" label="课程名字">
                  {{ class_info.title }}
                </a-descriptions-item>
                <a-descriptions-item label="开始时间~结束时间">
                  {{ class_info.start_time| dateFormat('YYYY-MM-DD') }}~{{ class_info.end_time| dateFormat('YYYY-MM-DD') }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="时长">
                  {{ class_info.study_time }} 小时
                </a-descriptions-item>
                <a-descriptions-item label="新建时间">
                  {{ class_info.created_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="最后更新时间">
                  {{ class_info.updated_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                </a-descriptions-item>
                <a-descriptions-item label="课程状态">
                  <a-badge v-if="class_info.status=== -1" status="warning" text="待发布"/>
                  <a-badge v-if="class_info.status=== 1" status="success" text="报名中"/>
                  <a-badge v-if="class_info.status=== 2" status="processing" text="已停止"/>
                  <a-badge v-if="class_info.status=== 3" status="processing" text="已暂停"/>
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="课程进程">
                  <a-badge v-if="class_info.flag=== 1" status="success" text="新建"/>
                  <a-badge v-if="class_info.flag=== 2" status="processing" text="报名中"/>
                  <a-badge v-if="class_info.flag=== 3" status="processing" text="课程进行中"/>
                  <a-badge v-if="class_info.flag=== 4" status="processing" text="课程停止"/>
                  <a-badge v-if="class_info.flag=== 5" status="processing" text="课程暂停"/>
                  <a-badge v-if="class_info.flag=== 6" status="processing" text="课程结束等待发证"/>
                  <a-badge v-if="class_info.flag=== 7" status="processing" text="发证完毕课程结束"/>
                </a-descriptions-item>
                <a-descriptions-item v-if="class_info.group_info" :span="3" label="报名人数">

                  {{ class_info.group_info.now_account }}/{{ class_info.group_info.max_account }} 人
                </a-descriptions-item>

                <!--                <a-descriptions-item label="证书字段">-->
                <!--                    <div v-for="item in class_info.certificates_input_info">-->
                <!--                      {{item.name}}-->
                <!--                    </div>-->
                <!--                </a-descriptions-item>-->
              </a-descriptions>
            </div>
<!--            {{ClassauserlistData}}-->
            <div>

            </div>
            <a-row justify="space-between" type="flex">
              <a-col :span="8">
                <!--                <h1>分类名称: {{ Class_name }}</h1>-->
                <a-button style="margin-right: 10px" type="primary" @click="refreshinfo">
                  刷新
                </a-button>
              </a-col>
              <a-col :span="12" style="text-align:right;margin-bottom:10px">
                <!--                <a-button style="margin-right: 10px" type="primary" @click="addArticle(Class_id)">-->
                <!--                  新建班级-->
                <!--                </a-button>-->
                <a-button style="margin-right: 10px" type="primary" @click="editClass(Class_id)" disabled>
                  添加学员
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="startClass(Class_id)" disabled>
                  剔除学员
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="successCourse">
                  毕业（发证）
                </a-button>
                <a-button style="margin-right: 10px" type="primary" @click="startClass(Class_id)">
                  结业（不发证）
                </a-button>
              </a-col>

            </a-row>
            <a-table
                :columns="columns"
                :dataSource="ClassauserlistData"
                :loading="loading"
                :pagination="pagination"
                bordered
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: ontableSelectChange }"
                rowKey="uid"
            >
              <template slot="updated_at" slot-scope="text, index" style="text-align: center">
                {{ text | dateFormat('YYYY-MM-DD HH:mm:ss') }}
              </template>
              <template slot="certificates_status" slot-scope="text, index" style="text-align: center">
                <a-tag v-if="index.certificates_status === 2" :color="'green'">进行中</a-tag>
                <a-tag v-if="index.certificates_status === 10" :color="'cyan'">已生成</a-tag>
                <a-tag v-if="index.certificates_status === 11" :color="'red'">未发证</a-tag>
              </template>
              <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="editArticlerank(index,Class_id)">查看用户详情</a>
                            </span>
<!--                <span style="margin-right: 5px;">-->
<!--                              <a @click="editArticle(index,Class_id)">编辑</a>-->
<!--                            </span>-->
<!--                <span>-->
<!--                              <a @click="deleArticle(index)">剔除</a>-->
<!--                            </span>-->
              </template>

            </a-table>
          </div>
        </a-col>
        <a-col :pull="20" :span="4">
          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-button block type="primary" @click="addClass()">
              新建课程
            </a-button>
            <!--                                    {{ treeData }}-->
            <a-tree :expandedKeys.sync="expandedKeys" :tree-data="ClasstreeData" showIcon showLine
                    @select="onContextMenuClick">

              <template slot="status" slot-scope="{ status }">
                <template v-if="status === '-1'">
                  <span class="api-method" style="color: #0bbb52">-1</span>
                </template>
                <template v-if="status === '1'">
                  <span class="api-method" style="color: #fcb100">1</span>
                </template>
                <template v-if="status === 2">
                  <span class="api-method" style="color: #0978e7">2</span>
                </template>
                <template v-if="status === 3">
                  <span class="api-method" style="color: #07c0e9">3</span>
                </template>
                <template v-if="status === 4">
                  <span class="api-method" style="color: #e71f12">4</span>
                </template>
                <template v-if="status === 5">
                  <span class="api-method" style="color: #e71f12">5</span>
                </template>
              </template>
            </a-tree>
          </div>
        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>

    <a-modal v-model="pop_Classadd" title="新增课程" width="75%" @ok="Class_addok">
      <a-row type="flex">
        <a-col flex="auto">
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="课程标题">
            <a-input v-model="add_Class.title"/>
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="开班时间范围">
            <a-range-picker @change="Changetimes"/>
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="时长">
            <a-input v-model="add_Class.study_time" style="width: 100px"/>
            小时
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="最大人数">
            <a-input v-model="add_Class.max_account" style="width: 100px"/>
            人
          </a-form-item>
          <a-divider>课程证书信息</a-divider>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="选择证书模板">
            <a-select style="width: 120px" @change="Changecertificates">
              <a-select-option v-for="(item,index) in certificatesModelData" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" label="编号信息">
            <a-input-group compact>
              <a-input
                  disabled
                  placeholder="TE"
                  style=" width: 70px; pointer-events: none; backgroundColor: #fff"/>
              <!--          /> <a-cascader v-model="add_Class.ncertificates_input_infoame.adder" style=" width: 150px;" :options="provinceCityinfo" placeholder="请选择省/市/区"-->
              <!--                          @change="ChangeprovinceCity"/>-->
              <a-input v-model="add_Class.ncertificates_input_infoame.adder" placeholder="请输入城市简写"
                       style=" width: 150px;"/>
              <a-select default-value="PD" style="width: 120px" @change="Changedirection">
                <a-select-option value="PD">
                  儿童
                </a-select-option>
                <a-select-option value="PY">
                  成人
                </a-select-option>
                <a-select-option value="GE">
                  老年
                </a-select-option>
              </a-select>
              <a-month-picker placeholder="选择日期" @change="Changemonth"/>
            </a-input-group>

          </a-form-item>

        </a-col>
        <a-col flex="250px">
          <div>课程证书预览</div>
          <div></div>
          <div>
            NO:{{
              add_Class.ncertificates_input_infoame.usertype + add_Class.ncertificates_input_infoame.adder + add_Class.ncertificates_input_infoame.direction + add_Class.ncertificates_input_infoame.yearmonth
            }}
          </div>
        </a-col>
      </a-row>
      <!--      <div v-html="certificatesModelinfo.content" class="democontent"></div>-->

    </a-modal>
    <a-modal v-model="pop_Classedit" title="编辑课程" width="75%" @ok="Class_editok">
      <a-row type="flex">
        <a-col flex="auto">
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="课程标题">
            <a-input v-model="edit_Class.title"/>
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="开班时间范围">
            <a-range-picker v-model="edit_Class.start_end" @change="Changetimes"/>
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="时长">
            <a-input v-model="edit_Class.study_time" style="width: 100px"/>
            小时
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="最大人数">
            <a-input v-model="edit_Class.max_account" style="width: 100px"/>
            人
          </a-form-item>
          <a-divider>课程证书信息</a-divider>

          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="选择证书模板">
            <a-select :default-value="edit_Class.certificates_model_id" style="width: 120px"
                      @change="Changecertificates">
              <a-select-option v-for="(item,index) in certificatesModelData" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" label="编号信息">
            <a-input-group compact>
              <a-input
                  disabled
                  placeholder="TE"
                  style=" width: 70px; pointer-events: none; backgroundColor: #fff"/>
              <!--          /> <a-cascader v-model="add_Class.ncertificates_input_infoame.adder" style=" width: 150px;" :options="provinceCityinfo" placeholder="请选择省/市/区"-->
              <!--                          @change="ChangeprovinceCity"/>-->
              <a-input v-model="edit_Class.ncertificates_input_infoame.adder" placeholder="请输入城市简写"
                       style=" width: 150px;"/>
              <a-select :default-value="edit_Class.ncertificates_input_infoame.direction" style="width: 120px"
                        @change="Changedirection">
                <a-select-option value="PD">
                  儿童
                </a-select-option>
                <a-select-option value="PY">
                  成人
                </a-select-option>
                <a-select-option value="GE">
                  老年
                </a-select-option>
              </a-select>
              <a-month-picker v-model="edit_Class.ncertificates_input_infoame.yearmonth" placeholder="选择日期"
                              @change="Changemonth"/>
            </a-input-group>

          </a-form-item>

        </a-col>
        <a-col flex="250px">
          <div>课程证书预览</div>
          <div></div>
          <div>
            NO:{{
              edit_Class.ncertificates_input_infoame.usertype + edit_Class.ncertificates_input_infoame.adder + edit_Class.ncertificates_input_infoame.direction + edit_Class.ncertificates_input_infoame.yearmonth
            }}
          </div>
        </a-col>
      </a-row>
      <!--      <div v-html="certificatesModelinfo.content" class="democontent"></div>-->
      <!--      {{edit_Class}}-->
    </a-modal>


  </a-layout>

</template>

<script>
// const treeData = [
//   {
//     title: '分类一',
//     key: '0',
//     children: [
//       {
//         title: '分类一一',
//         key: '00',
//         children: [
//           {title: '分类一一一', key: '001'},
//           {title: '分类一一二', key: '002'},
//           {title: '分类一一三', key: '003'},
//         ],
//       },
//       {
//         title: '分类一二',
//         key: '01',
//         children: [
//           {title: '分类一二一', key: '011'},
//           {title: '分类一二二', key: '012'},
//           {title: '分类一二三', key: '013'},
//         ],
//       },
//     ],
//   },
// ];
// import moment from "moment";

import Config from "../config";

const columns = [
  {
    title: "uid",
    dataIndex: "uid",
    width: "7%",
    scopedSlots: {customRender: "uid"}
  },
  // {
  //   title: "登录账号",
  //   dataIndex: "login_name",
  //   scopedSlots: {customRender: "login_name"}
  // },
  {
    title: "昵称",
    dataIndex: "name",
    scopedSlots: {customRender: "name"}
  },
  {
    title: "更新时间",
    dataIndex: "updated_at",
    scopedSlots: {customRender: "updated_at"}
  },


  {
    title: "状态",
    dataIndex: "certificates_status",
    scopedSlots: {customRender: "certificates_status"}
  },
  {
    title: '操作',
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";
import provinceCity from "../common/provinceCity.json";
import moment from "moment";
// import {quillEditor} from "vue-quill-editor";
// import "quill/dist/quill.snow.css"; //quill编辑器的样式表
// import * as Util from "../common/util";
export default {
  name: "Dashboard",
  // components: {quillEditor},
  computed: {
    hasSelected() {
      console.log("hasSelected");
      return this.selectedRowKeys.length > 0;
    },
  },
  data() {
    return {
      columns,

      provinceCityinfo: provinceCity.options,
      selectedKeys: ['1'],
      expandedKeys: ['1'],
      expandedaddKeys: ['1'],
      selectedRowKeys: [],
      data: [],
      loading: false,
      Class_show: false,
      pop_Classadd: false,
      pop_Classedit: false,
      demohtml: "",
      demono: "",
      Class_id: "",
      course_id: "",
      Class_name: "",
      class_info: "",
      ClasstreeData: [],
      certificatesModelinfo: "",
      certificatesModelData: [],
      ClassauserlistData: [],
      replaceFields: {
        children: 'child',
        title: 'name',
        label: 'title',
        key: 'id',
        value: 'value',
      },
      pagination: {
        pageSize: 10
      },
      add_Class: {
        p_id: "",
        title: "",
        group_name: "",

        start_time: "",
        end_time: "",
        certificates_model_id: "",
        certificates_input_info: "",
        ncertificates_input_infoame: {
          usertype: "TE",
          adder: "",
          direction: "PD",
          yearmonth: "",
        },
        type: "",
        certificates_code_prefix: "",
        study_time: "",
        max_account: "",
      },
      edit_Class: {
        id: "",
        p_id: "",
        title: "",
        group_name: "",
        start_end: [],
        start_time: "",
        end_time: "",
        certificates_model_id: "",
        ncertificates_input_infoame: {
          usertype: "TE",
          adder: "",
          direction: "PD",
          yearmonth: "",
        },
        type: 1,
        certificates_code_prefix: "",
        study_time: "",
        max_account: "",
      },
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
    };
  },
  created() {
    // 获取第一页内容
    this.getClasslist(1, 1000);
    this.getcertificatesModellist();

    // this.delClass(25);

    // Promise.all([this.getitem(), this.getresource()]).then(() => {
    //   this.loading = false;
    // }).catch((error) => {
    //   console.log(error)
    // })
  },

  methods: {
    ontableSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    onContextMenuClick(event, e) {
      console.log('event', event);
      // console.log('event.length', event.length);
      console.log('e', e);

      if (event.length === 0) {
        this.Class_show = false;
        return;
      }
      if (e.selectedNodes === []) {
        this.Class_show = false;

      } else {
        // console.log('length', e.selectedNodes[0].data.props.dataRef);

        this.Class_id = e.selectedNodes[0].data.props.dataRef.id
        this.Class_name = e.selectedNodes[0].data.props.dataRef.title
        console.log('dataRef', e.selectedNodes[0].data.props.dataRef);
        // console.log('Class_show2', this.Class_show2);
        if (e.selectedNodes[0].data.props.dataRef.more_info !== null) {
          console.log('不为空', this.content_cn)
          // return;
        } else {
          console.log('为空', this.content_cn)

        }
        console.log('Class_id', this.Class_id);
        console.log('Class_name', this.Class_name);
        this.Class_show = false;
        if (this.Class_pid !== null) {
          this.getclassinfo(this.Class_id)

          this.Class_show = true;
        }
      }
    },
    refreshinfo() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
        this.getclassinfo(this.Class_id);
      }, 1000);

    },

    async onChange(pagination) {
      await this.doSearch(
          this.search_text,
          pagination.current - 1,
          pagination.pageSize
      );
    },

    async onselectpid(value, node) {

      console.log(value);
      this.add_Class.p_id = value;
      console.log(node);

    },
    async onChanges(e) {
      console.log(e.target.value);
    },
    async Changetimes(date, dateString) {
      console.log(date, dateString);
      this.add_Class.start_time = dateString[0]
      this.add_Class.end_time = dateString[1]
      console.log(this.add_Class.start_time);
      console.log(this.add_Class.end_time);
    },
    async Changemonth(date, dateString) {
      this.add_Class.ncertificates_input_infoame.yearmonth = moment(dateString).format("YYYYMM");
      this.edit_Class.ncertificates_input_infoame.yearmonth = moment(dateString).format("YYYYMM");
      console.log(this.add_Class.ncertificates_input_infoame.yearmonth);
    },
    async Changecertificates(e) {
      console.log(e);
      this.add_Class.certificates_model_id = e
      this.getcertificatesModelinfo(e);

    },
    async Changedirection(e) {
      this.add_Class.ncertificates_input_infoame.direction = e
      this.edit_Class.ncertificates_input_infoame.direction = e
      console.log(e);

    },

    addClass() {
      console.log("点击");
      this.add_Class.title = "";
      this.add_Class.group_name = "";
      this.add_Class.start_time = "";
      this.add_Class.end_time = "";
      this.add_Class.certificates_model_id = "";
      this.add_Class.certificates_input_info = "";
      this.add_Class.ncertificates_input_infoame = {
        usertype: "TE",
        adder: "",
        direction: "PD",
        yearmonth: "",
      };
      this.add_Class.type = "";
      this.add_Class.certificates_code_prefix = "";
      this.add_Class.study_time = "";
      this.add_Class.max_account = "";
      this.pop_Classadd = true;
    },
    editClass(e) {
      console.log("点击", e);
      this.edit_Class.id = this.class_info.id;
      this.edit_Class.title = this.class_info.title;
      this.edit_Class.group_name = this.class_info.group_name;

      this.edit_Class.start_time = this.class_info.start_time;
      this.edit_Class.end_time = this.class_info.end_time;

      this.edit_Class.certificates_model_id = this.class_info.certificates_model_id;
      this.edit_Class.certificates_input_info = this.class_info.certificates_input_info;
      this.edit_Class.ncertificates_input_infoame = {
        usertype: "TE",
        adder: "",
        direction: "PD",
        yearmonth: "",
      };
      this.edit_Class.type = this.class_info.type;
      this.edit_Class.certificates_code_prefix = this.class_info.certificates_code_prefix;
      this.edit_Class.study_time = this.class_info.study_time;
      this.edit_Class.max_account = this.class_info.group_info.max_account;
      if (e) {
        this.getclassinfo(e);
        this.pop_Classedit = true;
      }
    },
    async startClass(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/course/check", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.Class_id,
      });
      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        this.getclassinfo(this.Class_id)
        Modal.info({
          title: "发布成功"
        });
      }
    },
    async successCourse() {
      let result = await this.$post("/api/admin/certificate/successCourseByCustomers", {
        uid: Storage.uid,
        token: Storage.token,
        course_id: this.Class_id,
        customers: this.selectedRowKeys,
      });
      if (result.status === true) {
        console.log("result", result);
        await this.automake()
      }
    },
    async automake() {
      let result = await this.$post("/api/autoWork/createdCertificatesFile", {
        uid: Storage.uid,
        token: Storage.token,
      });
      if (result.status === true) {
        console.log("result", result);
      }
    },
    async stopClass(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/course/stop", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.Class_id,
      });
      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        this.getclassinfo(this.Class_id)
        Modal.info({
          title: "暂停成功"
        });
      }
    },
    async releaseClass(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/course/release", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.Class_id,
      });
      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        this.getclassinfo(this.Class_id)
        Modal.info({
          title: "恢复成功"
        });
      }
    },async terminationClass(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/course/termination", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.Class_id,
      });
      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        this.getclassinfo(this.Class_id)
        Modal.info({
          title: "中止成功"
        });
      }
    },
    async deleteClass(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/course/delete", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.Class_id,
      });
      if (result.status === true) {
        this.class_info = []
        await this.getClasslist(1, 1000);
        this.getclassinfo(this.Class_id)
        Modal.info({
          title: "剔除成功"
        });
      }
    },
    async saveClassinfo(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/articleClass/update", {
        uid: Storage.uid,
        token: Storage.token,
        articles_Class_id: this.Class_id,
        save_value: {
          // name:this.Class_name,
          more_info: {
            cn: this.content_cn,
            en: this.content_en,
            fr: this.content_fr,
          }
        },
      });
      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        Modal.info({
          title: "修改成功"
        });
      }
    },

    async Class_addok() {
      if (this.add_Class.title === "") {
        Modal.warning({
          title: "栏目名不能为空"
        });
        this.pop_Classadd = true;
        return
      }

      let result = await this.$post("/api/admin/course/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          start_time: this.add_Class.start_time,
          end_time: this.add_Class.end_time,
          title: this.add_Class.title,
          certificates_model_id: this.add_Class.certificates_model_id,
          certificates_input_info: this.add_Class.certificates_input_info,
          type: 1,
          certificates_code_prefix: this.add_Class.ncertificates_input_infoame.usertype + this.add_Class.ncertificates_input_infoame.adder + this.add_Class.ncertificates_input_infoame.direction + this.add_Class.ncertificates_input_infoame.yearmonth,
          study_time: this.add_Class.study_time,
          group_name: this.add_Class.group_name, //预留
          is_homework: this.add_Class.is_homework,//预留
          max_account: this.add_Class.max_account,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Classadd = false;
    },


    async getClasslist(page, limit) {
      this.loading = true;
      let result = await this.$get("/api/admin/course/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          limit: limit,
          page: page,
        }
      });

      if (result.status === true) {
        let data = [];

        for (let i in result.data.list) {
          let info = result.data.list[i];
          if (info.status === -1) {
            var text = "待发布";
          } else {
            text = "";
          }
          if (info.status === 1) {
            var text1 = "报名中";
          } else {
            text1 = "";
          }
          if (info.status === 2) {
            var text2 = "已停止";
          } else {
            text2 = "";
          }
          if (info.status === 3) {
            var text3 = "已暂停";
          } else {
            text3 = "";
          }

          data[i] = {
            key: info.id,
            id: info.id,
            value: info.id,
            name: info.title + '[' + text + text1 + text2 + text3 + ']',
            label: info.title + '[' + text + text1 + text2 + text3 + ']',
            title: info.title + '[' + text + text1 + text2 + text3 + ']',
            status: info.status,
            created_at: info.created_at,
            updated_at: info.updated_at,
            type: info.type,
          };
        }
        this.ClasstreeData = data;
        console.log(this.ClasstreeData)
        this.loading = false;
      }

      this.loading = false;
    },
    async getcertificatesModellist() {
      this.loading = true;
      let result = await this.$get("/api/admin/certificatesModel/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
        }
      });

      if (result.status === true) {

        this.certificatesModelData = result.data;
        this.loading = false;
      }

      this.loading = false;
    },
    async getcertificatesModelinfo(id) {
      this.loading = true;
      let result = await this.$get("/api/admin/certificatesModel/info", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          id: id,
        }
      });

      if (result.status === true) {

        this.certificatesModelinfo = result.data;
        this.add_Class.certificates_input_info = result.data.key_content;
        this.loading = false;
      }

      this.loading = false;
    },
    async getclassinfo(id) {
      this.loading = true;
      this.ClassauserlistData=[];
      let result = await this.$get("/api/admin/course/info", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          id: id,
        }
      });

      if (result.status === true) {

        this.class_info = result.data;
        console.log("class_info", result.data);
        this.edit_Class.start_end.push(result.data.start_time);
        this.edit_Class.start_end.push(result.data.end_time);
        this.edit_Class.ncertificates_input_infoame.usertype = result.data.certificates_code_prefix.slice(0, 2)
        this.edit_Class.ncertificates_input_infoame.adder = result.data.certificates_code_prefix.slice(2, 4)
        this.edit_Class.ncertificates_input_infoame.direction = result.data.certificates_code_prefix.slice(4, 6)
        this.edit_Class.ncertificates_input_infoame.yearmonth = moment(result.data.certificates_code_prefix.slice(6, 12))

        this.ClassauserlistData = result.data.group_info.accounts
        console.log("ClassarticleData", this.ClassauserlistData);
        this.course_id=result.data.group_info.id
        // this.getClassauserlist(1, 20, "", result.data.group_info.id, true)
        // this.edit_Class = result.data;
        this.loading = false;
      }

      this.loading = false;
    },


    async getClassauserlist(page, limit, search, account_groups_id, is_in) {
      this.loading = true;
      this.ClassarticleData = [];
      let result = await this.$post("/api/admin/customer/list", {

        uid: Storage.uid,
        token: Storage.token,
        limit: limit,
        page: page,
        search: search,
        account_group_info: {
          account_groups_id: account_groups_id,
          is_in: is_in,
        },
        // t: now,

      });

      if (result.status === true) {
        console.log(result.data.list)

        this.ClassauserlistData = result.data.list;

        this.loading = false;
      }

      this.loading = false;
    },

    async Class_editok() {
      console.log(this.edit_Class.rank)
      if (this.edit_Class.title === "") {
        Modal.warning({
          title: "栏目名不能为空"
        });
        this.pop_Classadd = true;
        return
      }

      let result = await this.$post("/api/admin/course/update", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.edit_Class.id,
        save_value: {
          start_time: this.edit_Class.start_time,
          end_time: this.edit_Class.end_time,
          title: this.edit_Class.title,
          certificates_model_id: this.edit_Class.certificates_model_id,
          certificates_input_info: this.edit_Class.certificates_input_info,
          // type: 1,
          certificates_code_prefix: this.edit_Class.ncertificates_input_infoame.usertype + this.edit_Class.ncertificates_input_infoame.adder + this.edit_Class.ncertificates_input_infoame.direction + this.edit_Class.ncertificates_input_infoame.yearmonth,
          study_time: this.edit_Class.study_time,
          group_name: this.edit_Class.group_name, //预留
          is_homework: this.edit_Class.is_homework,//预留
          max_account: this.edit_Class.max_account,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.getClasslist(1, 1000);
        Modal.info({
          title: "修改成功"
        });
      }
      this.Class_name = this.edit_Class.name.cn;
      this.pop_Classedit = false;
    },


    async delClass(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除课程?",

        onOk: async function () {

          let result = await that.$post("/api/admin/course/delete", {

            uid: Storage.uid,
            token: Storage.token,
            id: item,
            // notes: this.edit_pop.notes,
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getClasslist(1, 1000);
            that.Class_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
    async deleArticle(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该班级?",

        onOk: async function () {

          let result = await that.$post("/api/admin/article/delete", {

            uid: Storage.uid,
            token: Storage.token,
            article_id: item.id,
            // notes: this.edit_pop.notes,
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getClassauserlist(1, 20, "", this.Class_id, 1)
            // that.Class_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },

  }
};
</script>
<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}

.ant-uploads /deep/ .ant-upload.ant-upload-select-picture-card {
  width: 200px !important;
  height: 200px !important;
}

.ant-uploads /deep/ .ant-upload-list-picture-card .ant-upload-list-item {
  width: 200px !important;
  height: 200px !important;
}

.ant-uploads /deep/ .ant-upload-list-picture-card-container {
  width: 200px !important;
  height: 200px !important;
}

.ant-tabs {
  overflow: unset !important;
}

.note {
  line-height: 110%;
  font-size: 12px;
  color: #ccc;
}

.democontent {
  height: 400px;
  width: 580px;
  margin-right: auto;
  margin-left: auto;

  & /deep/ .username {
    margin-top: 180px !important;
    font-size: 16px;
  }

  & /deep/ .PROJECTNAME {
    margin-top: 16px !important;
    font-size: 14px;
  }

  & /deep/ .HOURS {
    margin-top: 0px !important;
    font-size: 9px;
  }

  & /deep/ .information {
    margin-top: 0px !important;
    font-size: 9px;
  }

  & /deep/ .mechanism img {
    margin-top: 0px !important;
    height: 20px;
  }

  & /deep/ .left {
    padding-left: 60px
  }

  & /deep/ .right {
    padding-right: 60px;
  }
}
</style>
